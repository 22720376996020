/**
* Template Name: Sailor - v4.7.0
* Template URL: https://bootstrapmade.com/sailor-free-bootstrap-theme/
* Author: BootstrapMade.com
* License: https://bootstrapmade.com/license/
*/

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/

@font-face {
    font-family: "Melinda Script";
    src: url("//db.onlinewebfonts.com/t/aac36178e1ef3e2c5c808bb006f41163.eot");
    src: url("//db.onlinewebfonts.com/t/aac36178e1ef3e2c5c808bb006f41163.eot?#iefix")
            format("embedded-opentype"),
        url("//db.onlinewebfonts.com/t/aac36178e1ef3e2c5c808bb006f41163.woff2")
            format("woff2"),
        url("//db.onlinewebfonts.com/t/aac36178e1ef3e2c5c808bb006f41163.woff")
            format("woff"),
        url("//db.onlinewebfonts.com/t/aac36178e1ef3e2c5c808bb006f41163.ttf")
            format("truetype"),
        url("//db.onlinewebfonts.com/t/aac36178e1ef3e2c5c808bb006f41163.svg#Melinda Script")
            format("svg");
}

body {
    font-family: Helvetica Neue;
    color: #333333;
    background: #ffffff;
}

a {
    text-decoration: none;
    color: #0052cc;
}

a:hover {
    color: #0052cc;
    text-decoration: none;
}

.form-group a {
    text-decoration: underline;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
li,
span,
button,
input,
optgroup,
select,
textarea {
    color: #333333;
    line-height: 1.5em;
    letter-spacing: 1.28px;
}

label {
    font: normal normal 300 21px/32px Helvetica Neue;
    letter-spacing: 0.53px;
    color: #333333;
}

.font-18 {
    font-size: 18px;
}

.btn-secondary {
    background-color: #666666;
}

hr {
    border: none;
    margin-top: 3rem;
}

.hr-white {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 5px solid #ffffff;
}

.grey-bg {
    background: #f9f9f9 !important;
}

img {
    image-rendering: -webkit-optimize-contrast;
}

.container-1200,
.container-1400,
.container-1080,
.container-900 {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 1200px) {
    .container-1200 {
        max-width: 1200px !important;
        margin: auto;
    }
    .container-1400 {
        max-width: 1400px !important;
        margin: auto;
    }
    .container-1080 {
        max-width: 1080px !important;
        margin: auto;
    }
    .container-1000 {
        max-width: 1000px !important;
        margin: auto;
    }
    .container-900 {
        max-width: 900px !important;
        margin: auto;
    }
}

.container-900 {
    background: #f9f9f9 !important;
    padding: 25px 20px 25px 20px;
    margin-top: 67px;
    margin-bottom: 100px;
    border: 0.25px solid rgba(0, 0, 0, 0.1);
}

.modal {
    justify-content: center;
    align-content: center;
    flex-direction: column;
    max-width: 900px !important;
    height: 480px !important;
    padding-top: 100px;
    overflow: visible;
    vertical-align: baseline;
}

.modal a.close-modal {
    top: 5px;
    right: 15px;
}

.modal p {
    font-size: 26px;
    color: #333333;
}

.modal_btn {
    margin-left: auto;
    margin-right: auto;
    margin-top: 60px;
}

.modal_bg {
    width: 300px;
    position: absolute;
    top: -30%;
    left: 33.5%;
}

.modal_hourglass {
    width: 150px;
    position: absolute;
    top: -22%;
    left: 42%;
}

.breadcrumb {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin-bottom: 0;
    margin-top: 0;
    list-style: none;
    background: transparent;
}

.breadcrumbs_wrap {
    position: relative;
    border-top: 1px solid #fac19c;
    border-bottom: 1px solid #fac19c;
}

.breadcrumbs_wrap nav {
    margin-left: 30px;
    padding: 13px 0;
}

.breadcrumb a {
    color: #333333;
}

.breadcrumb-item.active {
    font-size: 14px;
    color: #ff6600;
}

.breadcrumbs_house {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 13px;
}

.p-30 {
    padding: 30px 0px 0px 0px;
}

.pt-100 {
    padding-top: 100px;
}

.pb-100 {
    padding-bottom: 100px;
}

.ml-50 {
    margin-left: 50px;
}

.ml-145 {
    margin-left: 145px;
}

.m-15 {
    margin-left: 15px;
    margin-right: 15px;
}

.m-100 {
    margin-left: 100px;
    margin-right: 100px;
}

.mt-50 {
    margin-top: 50px;
}

.mt-100 {
    margin-top: 100px;
}

.mb-100 {
    margin-bottom: 100px;
}

.pagination {
    display: inline-flex;
}

.pagination li {
    margin-left: 30px;
    margin-right: 30px;
}

.page-link {
    padding: 15px 50px;
    color: #333;
    border-radius: 10px !important;
}

.page-link:hover {
    color: #333;
}

.page-item.active .page-link {
    color: #ff6600;
    font-size: 20px;
    background: transparent;
    border: none;
}

.page-item.disabled .page-link {
    color: #ccc !important;
}

.clearfix {
    clear: both;
}

.float-right {
    float: right;
}

.marble-bg {
    background: url(/images/Marble.jpg);
    background-size: 100% 100%;
}

.form-control {
    border: 0.5px solid #333333;
    border-radius: 7px;
    background: #fff;
}

.form-control {
    height: 80px;
}

.submit-btn {
    background: #999999 0% 0% no-repeat padding-box;
    border-radius: 7px;
    margin-top: 20px;
    border: none;
    padding: 10px 18px;
    font: normal normal 300 18px/22px Helvetica Neue;
    letter-spacing: 0.45px;
    color: #ffffff;
}

.submit-btn:hover {
    background: #999999 0% 0% no-repeat padding-box;
    color: #ffffff;
}

/*--------------------------------------------------------------
  # Back to top button / Toll Truck button
--------------------------------------------------------------*/
.back-to-top {
    position: fixed;
    right: 3%;
    bottom: 14%;
    z-index: 996;
    background: transparent;
    width: 56px;
    height: 56px;
    border-radius: 4px;
    transition: all 0.4s;
    border: none;
}

.back-to-top img {
    width: 56px;
    height: 56px;
    margin-top: 10px;
    border: 1px solid #ff6600;
    border-radius: 50%;
}

.back-to-top.active {
    visibility: visible;
    opacity: 1;
}

/*--------------------------------------------------------------
  # Sticky sodebnar start
  --------------------------------------------------------------*/
#main-content {
    margin: 20px 10px;
    position: absolute;
    left: -28%;
    top: 5%;
}

#content {
    float: right;
    border: 1px solid #aaaaaa;
    padding: 15px;
    margin-left: 15px;
    min-height: 2000px;
    color: #aaaaaa;
    width: calc(100% - 215px);
}

#sidebar .sidebar__inner ul {
    list-style: none;
    display: inline;
    border: none;
}

#sidebar .sidebar__inner ul li {
    width: 165px;
    height: 50px;
    background: #bbbbbb 0% 0% no-repeat padding-box;
    border-radius: 30px;
    padding: 0;
    margin-bottom: 15px;
    display: grid;
    align-items: center;
}

#sidebar .sidebar__inner ul .active {
    background: #ff934c;
    border: none;
}

#sidebar .sidebar__inner a {
    color: #ffffff;
    font-size: 16px;
}

#sidebar .sidebar__inner p {
    color: #ffffff;
    padding: 0;
    margin: 10px;
}

/*--------------------------------------------------------------
  # Header
  --------------------------------------------------------------*/
#header {
    background: white;
    transition: all 0.5s;
    z-index: 997;
    padding: 20px 0;
}
#header h4 {
    font-size: 24px;
    color: #333;
    margin-bottom: 0;
}
#header.header-scrolled {
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
    padding: 12px 0;
}
#header.header-inner-pages {
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}
#header .logo-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
}
#header .logo {
    font-size: 28px;
    margin: 0;
    padding: 0;
    line-height: 1;
    font-weight: 700;
    text-transform: uppercase;
    align-items: center;
}
#header .logo a {
    color: #ff6600;
}
#header .logo img {
    max-height: 60px;
}
.banner-container-inner .item .overlay:after {
    content: "";
    background: rgba(0, 0, 0, 0.7);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
}

.banner-title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
}

.banner-title h1 {
    font-size: 36px;
    letter-spacing: 3.6px;
    color: #ffffff;
}

.banner-title p {
    font: normal normal normal 16px/28px Helvetica Neue;
    letter-spacing: 1.28px;
    color: #ffffff;
    text-transform: uppercase;
}

.icon-next {
    position: absolute;
    bottom: 25px;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0 auto;
}

/*--------------------------------------------------------------
  # Navigation Menu
--------------------------------------------------------------*/
/**
  * Desktop Navigation
  */
.navbar {
    padding: 0;
}
.navbar ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    align-items: center;
}
.navbar li {
    position: relative;
    padding: 5px 20px;
}

.navbar li:after {
    content: "";
    position: absolute;
    left: 50%;
    bottom: -2px;
    width: 0px;
    height: 2px;
    background: #ccc;
    transition: all 0.45s;
}

.navbar li:hover:after {
    width: 71.5%;
    left: 16px;
}

.navbar li a:hover {
    text-decoration: none;
}

.navbar .social-list:after {
    pointer-events: none;
    display: none;
}

.navbar .social-list:hover:after {
    pointer-events: none;
    display: none;
}

.navbar .social-list {
    padding-left: 5px;
    padding-right: 0;
}

.navbar .social-icon {
    width: 30px;
}

.navbar a,
.navbar a:focus {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* padding: 10px 0 10px 50px; */
    font-size: 16px;
    font-weight: 500;
    color: #333333;
    white-space: nowrap;
    transition: 0.3s;
}
.navbar a i,
.navbar a:focus i {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;
}
.navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover > a {
    color: #cccccc;
}
.navbar .getstarted,
.navbar .getstarted:focus {
    background: #ff6600;
    padding: 8px 25px;
    margin-left: 30px;
    border-radius: 4px;
    font-weight: 400;
    color: #fff;
}
.navbar .getstarted:hover,
.navbar .getstarted:focus:hover {
    color: #fff;
    background: #ff6600;
}
.navbar .dropdown ul {
    display: block;
    position: absolute;
    left: 14px;
    top: calc(100% + 30px);
    margin: 0;
    /* padding: 10px 0; */
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
    transition: 0.3s;
}
.navbar .dropdown ul li {
    min-width: 100%;
}
.navbar .dropdown ul a {
    padding: 10px 30px 10px 0px;
    font-size: 15px;
    text-transform: none;
    font-weight: 400;
}
.navbar .dropdown ul a i {
    font-size: 12px;
}
.navbar .dropdown ul li:hover {
    border-top: 2px solid #ff6600;
    background: #f0f0f0;
}
.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover > a {
    color: #ff6600;
}
.navbar .dropdown:hover > ul {
    opacity: 1;
    top: 100%;
    visibility: visible;
}
.navbar .dropdown .dropdown ul {
    top: 0;
    left: calc(100% - 30px);
    visibility: hidden;
}
.navbar .dropdown .dropdown:hover > ul {
    opacity: 1;
    top: 0;
    left: 100%;
    visibility: visible;
}

@media (max-width: 1366px) {
    .navbar .dropdown .dropdown ul {
        left: -90%;
    }
    .navbar .dropdown .dropdown:hover > ul {
        left: -100%;
    }
}

/**
  * Mobile Navigation
  */
.mobile-nav-toggle {
    color: #556270;
    font-size: 28px;
    cursor: pointer;
    display: none;
    line-height: 0;
    transition: 0.5s;
}
.mobile-nav-toggle.bi-x {
    color: #fff;
}

@media (max-width: 1025px) {
    .mobile-nav-toggle {
        display: block;
    }

    .navbar ul {
        display: none;
    }
}
.navbar-mobile {
    position: fixed;
    overflow: hidden;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(63, 73, 83, 0.9);
    transition: 0.3s;
    z-index: 999;
}
.navbar-mobile .mobile-nav-toggle {
    position: absolute;
    top: 15px;
    right: 15px;
}

.navbar-mobile ul {
    display: inline-grid;
    position: absolute;
    top: 55px;
    right: 15px;
    bottom: 15px;
    left: 15px;
    padding: 10px 0;
    background-color: #fff;
    overflow-y: auto;
    transition: 0.3s;
    text-align: center;
    align-content: center;
    justify-content: center;
}

.navbar-mobile a,
.navbar-mobile a:focus {
    padding: 10px 20px;
    font-size: 15px;
    color: #556270;
}
.navbar-mobile a:hover,
.navbar-mobile .active,
.navbar-mobile li:hover > a {
    color: #d9232d;
}
.navbar-mobile .getstarted,
.navbar-mobile .getstarted:focus {
    margin: 15px;
}
.navbar-mobile .dropdown ul {
    position: static;
    display: none;
    margin: 10px 20px;
    padding: 10px 0;
    z-index: 99;
    opacity: 1;
    visibility: visible;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}
.navbar-mobile .dropdown ul li {
    min-width: 200px;
}
.navbar-mobile .dropdown ul a {
    padding: 10px 20px;
}
.navbar-mobile .dropdown ul a i {
    font-size: 12px;
}
.navbar-mobile .dropdown ul a:hover,
.navbar-mobile .dropdown ul .active:hover,
.navbar-mobile .dropdown ul li:hover > a {
    color: #d9232d;
}
.navbar-mobile .dropdown > .dropdown-active {
    display: block;
}

/*--------------------------------------------------------------
  # Header
--------------------------------------------------------------*/
/* .philosophy .banner-container-inner {
    height: 600px;
}

.philosophy .banner-container-inner img {
    height: 600px;
} */

.philosophy .banner-container-inner .banner-title {
    position: absolute;
    top: 50%;
    left: 25%;
    transform: translate(-50%, -25%);
    z-index: 2;
}

/*--------------------------------------------------------------
  # Footer
--------------------------------------------------------------*/
#footer {
    background: #e6e6e6;
    color: #333333;
    font-size: 14px;
}
#footer .logo-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    bottom: 18px;
}
#footer .footer-nav-wrap {
    align-items: center;
    justify-content: left;
}
#footer .logo-img {
    max-height: 70px;
}
#footer .footer-nav .social-list {
    padding-right: 5px;
    padding-left: 0px;
}
#footer .vertical-line {
    display: inline-block;
    border-left: 0.5px solid #707070;
    margin: 0 10px;
    margin-left: 150px;
    height: 320px;
}
#footer .social-icon {
    width: 30px;
}
#footer ul {
    margin: 0;
    padding: 0;
    list-style: none;
    align-items: center;
}
#footer .footer-nav li {
    margin-bottom: 15px;
}
#footer .footer-social li {
    position: relative;
    padding: 0 5px;
}
#footer .footer-social .social-icon {
    width: 40px;
    height: 40px;
    image-rendering: unset !important;
}
#footer a,
#footer a:focus {
    display: flex;
    font-size: 16px;
    color: #333;
    letter-spacing: 1.28px;
}
#footer p {
    font-size: 16px;
}
#footer .footer-top {
    background: #e6e6e6;
}
#footer .footer-top .footer-info {
    margin-bottom: 30px;
}
#footer .footer-top .footer-info h3 {
    font-size: 24px;
    margin: 0 0 20px 0;
    padding: 2px 0 2px 0;
    line-height: 1;
    font-weight: 700;
}
#footer .footer-top .footer-info p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 0;
    color: #fff;
}
#footer .footer-top .social-links a {
    font-size: 28px;
    display: inline-block;
    background: rgba(255, 255, 255, 0.1);
    color: #333333;
    line-height: 1;
    padding: 8px 0;
    margin-right: 4px;
    border-radius: 4px;
    text-align: center;
    width: 36px;
    height: 36px;
    transition: 0.3s;
}
#footer .footer-top .social-links a:hover {
    background: #d9232d;
    color: #fff;
    text-decoration: none;
}
#footer .footer-top h4 {
    font-size: 24px;
    font-weight: 500;
    color: #333;
    position: relative;
    letter-spacing: 1.2px;
}
#footer .footer-top .footer-links {
    margin-bottom: 30px;
}
#footer .footer-top .footer-links ul {
    list-style: none;
    padding: 0;
    margin: 0;
}
#footer .footer-top .footer-links ul i {
    padding-right: 2px;
    color: rgba(255, 255, 255, 0.6);
    font-size: 18px;
    line-height: 1;
}
#footer .footer-top .footer-links ul li {
    padding: 10px 0;
    display: flex;
    align-items: center;
}
#footer .footer-top .footer-links ul li:first-child {
    padding-top: 0;
}
#footer .footer-top .footer-links ul a {
    color: rgba(255, 255, 255, 0.6);
    transition: 0.3s;
    display: inline-block;
    line-height: 1;
}
#footer .footer-top .footer-links ul a:hover {
    color: white;
}

#footer .copyright {
    padding: 65px 0;
    border-top: 0.5px solid #707070;
}

#footer .copyright p {
    font-size: 18px;
}

/*--------------------------------------------------------------
  # Home / Content Misc
--------------------------------------------------------------*/
.title {
    margin: 150px 0px;
}

.title h3 {
    font-size: 24px;
    color: #333333;
    font-weight: bold;
    margin-bottom: 0;
}

.title h2 {
    font-size: 36px;
    color: #333333;
    font-weight: bold;
    margin-bottom: 0;
    line-height: 1em;
}

.title span {
    display: inline-block;
    text-align: center;
    font: normal normal 300 12px/15px Helvetica Neue;
    letter-spacing: 2.4px;
    color: #999999;
    text-transform: uppercase;
    margin-bottom: 50px;
}

.title p {
    text-align: center;
    font: normal normal 300 21px/21px Helvetica Neue;
    letter-spacing: 4.2px;
    color: #333333;
}

.content-container .info-text-wrap {
    position: relative;
}

/* .content-container img {
    height: 800px;
} */

.content-container .info-text {
    position: absolute;
    top: 35%;
    left: 0;
    right: 0;
    padding: 0 160px;
}

.content-container .info-text p {
    text-align: left;
    font: normal normal 300 21px/32px Helvetica Neue;
    letter-spacing: 4.2px;
    color: #333333;
}

.service-wrap {
    margin-bottom: 5px;
}

.home-service-container .service-wrap .service-title {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin-bottom: 50px;
}

.home-service-container .service-wrap .service-title h2 {
    font: normal normal bold 24px/29px Helvetica Neue;
    letter-spacing: 2.4px;
    color: #ffffff;
    text-shadow: 0px 0px 5px #000000;
    opacity: 1;
}

.home-service-container .service-wrap .service-title p {
    font: normal normal 100 21px/25px Helvetica Neue;
    letter-spacing: 1.05px;
    color: #ffffff;
    text-shadow: 0px 0px 5px #000000;
    opacity: 1;
    margin-bottom: 0;
}

/*--------------------------------------------------------------
  # Service
--------------------------------------------------------------*/
.service-container .service-span {
    text-align: left;
    font: normal normal normal 150px/150px Melinda Script;
    letter-spacing: -15px;
    color: #999999;
    opacity: 1;
    position: relative;
    top: 30px;
    display: inline-block;
    width: 80px;
}

.service-container .info-text {
    position: relative;
    top: 5%;
    left: 0;
    right: 0;
    padding: 0 160px;
}

.service-container .info-text.service-even {
    left: 25%;
}

.service-container .info-text p {
    border-bottom: 1px solid #ccc;
    width: 65%;
    line-height: 3em !important;
    text-align: left;
    font: normal normal bold 24px/29px Helvetica Neue;
    letter-spacing: 2.4px;
    color: #000000;
}

.service-container .service-list {
    list-style: none;
    margin-left: 50px;
}

.service-container .service-list li {
    text-align: left;
    font: normal normal 100 21px/25px Helvetica Neue;
    letter-spacing: 1.05px;
    color: #333333;
}

.service-pricing-container {
    margin: auto;
    padding: 100px 0;
}

.service-pricing-container .title {
    margin: 0;
}

/*--------------------------------------------------------------
  # Pricing
--------------------------------------------------------------*/

.price-container .price-box {
    min-height: 100%;
    border: 0.5px solid #333333;
}

.price-container .info-text {
    padding: 50px 20px;
}

.price-container .service-span {
    text-align: left;
    font: normal normal normal 150px/150px Melinda Script;
    letter-spacing: -15px;
    color: #999999;
    opacity: 1;
    position: relative;
    top: -45px;
    display: inline-block;
    width: 80px;
}

.pricing-title {
    position: absolute;
    top: 50%;
    left: 40%;
    transform: translate(-50%, -50%);
}

.pricing-title h2 {
    font: normal normal medium 36px/48px Helvetica Neue;
    letter-spacing: 1.8px;
    color: #333333;
    text-align: left;
}

.price-container .info-text p {
    width: 100%;
}

.price-container .service-list {
    margin-left: 0;
    padding-left: 0;
}

.price-container .service-list li:last-child {
    margin-top: 20px;
    font: normal normal 300 21px/25px Helvetica Neue;
    letter-spacing: 1.05px;
    color: #333333;
}

/*--------------------------------------------------------------
  # Portfolio
--------------------------------------------------------------*/
.jQueryMosaic>.item>.overlay {
    cursor: pointer;
}

.portfolio-section {
    background: #f5f6f6 0% 0% no-repeat padding-box;
}

.portfolio-container .overlay {
    position: absolute;
    bottom: 0;
    left: 2.4%;
    right: 0;
    background: transparent
        linear-gradient(360deg, #000000 0%, #2b2b2b 58%, #54545400 100%) 0% 0%
        no-repeat padding-box;
    overflow: hidden;
    width: 95.3%;
    height: 0;
    transition: 0.5s ease;
}

.portfolio-container .portfolio-col {
    position: relative;
}

.portfolio-container .portfolio-col:hover .overlay {
    height: 30%;
}

.portfolio-container .portfolio-col .overlay .text {
    color: white;
    font-size: 20px;
    position: absolute;
    top: 75%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
}

/*--------------------------------------------------------------
  # Portfolio Residential / Commercial
--------------------------------------------------------------*/
.gallery-container .row {
    margin-bottom: 10px;
}

.gallery-container img {
    height: 650px;
    object-fit: cover;
}

/* Content Image Overlay */
.content {
    position: relative;
    margin: auto;
    overflow: hidden;
}

.content .content-overlay {
    background: rgba(0, 0, 0, 0.7);
    position: absolute;
    height: 99%;
    width: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    -webkit-transition: all 0.4s ease-in-out 0s;
    -moz-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
}

.content:hover .content-overlay {
    opacity: 1;
}

.content-image {
    width: 100%;
}

.content-details {
    position: absolute;
    text-align: center;
    padding-left: 1em;
    padding-right: 1em;
    width: 100%;
    top: 50%;
    left: 50%;
    opacity: 0;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-transition: all 0.3s ease-in-out 0s;
    -moz-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
}

.content:hover .content-details {
    top: 50%;
    left: 50%;
    opacity: 1;
}

.content-details h3 {
    color: #fff;
    font-weight: 500;
    letter-spacing: 0.15em;
    margin-bottom: 0.5em;
    text-transform: uppercase;
}

.content-details p {
    color: #fff;
    font-size: 0.8em;
}

.fadeIn-bottom {
    top: 80%;
}

.gallery-section .random-container {
    padding: 0px 100px 65px 100px;
}

.gallery-section .random-container .title {
    padding: 100px 0px 0px 0px !important;
    margin: 0px !important;
}

.gallery-section .random-portfolio {
    max-height: 420px;
}

/*--------------------------------------------------------------
  # Contact Us
--------------------------------------------------------------*/

.contact-info {
}

.contact-info h3 {
    font: normal normal 24px/32px Helvetica Neue;
    letter-spacing: 0.6px;
    color: #333333;
    margin-bottom: 30px;
}

.contact-info p {
    font: normal normal 300 21px/32px Helvetica Neue !important;
    letter-spacing: 0.53px;
    color: #333333;
}

/*--------------------------------------------------------------
  # FAQ
--------------------------------------------------------------*/

.faq-section .faq-container h2 {
    text-align: left;
    font-size: 24px;
    letter-spacing: 1.2px;
    color: #333333;
    opacity: 1;
}

.faq-section .faq-container p {
    font-size: 21px;
    letter-spacing: 1.05px;
    color: #333333;
    opacity: 1;
}

.faq-section .faq-container hr {
    margin-top: 20px;
    height: 1.5px;
    color: #707070;
}

.faq-section .faq-container .faq-div {
    margin-bottom: 100px;
}

/*--------------------------------------------------------------
  # Book Now
--------------------------------------------------------------*/

/* #grad1 {
    background-color: #9c27b0;
    background-image: linear-gradient(120deg, #ff4081, #81d4fa);
} */

#msform {
    text-align: center;
    position: relative;
    margin-top: 20px;
}

#msform fieldset .form-card {
    background: white;
    border: 0 none;
    padding: 0px 40px;
    box-sizing: border-box;
    width: 73%;
    margin: 0 3% 0px 3%;
    margin: auto;
}

#msform fieldset {
    background: white;
    border: 0 none;
    border-radius: 0.5rem;
    box-sizing: border-box;
    width: 100%;
    margin: 0;
    padding-bottom: 20px;
    position: relative;
}

#msform fieldset:not(:first-of-type) {
    display: none;
}

#msform fieldset .form-card {
    text-align: left;
    color: #9e9e9e;
    padding: 0px;
}

#msform input,
#msform textarea {
    padding: 24px;
    border: none;
    border-radius: 0px;
    margin-bottom: 25px;
    margin-top: 2px;
    width: 70.5%;
    box-sizing: border-box;
    font-family: montserrat;
    color: #2c3e50;
    font-size: 16px;
    letter-spacing: 1px;
    border: 0.5px solid #333333;
    border-radius: 7px;
}

#msform input[type="button"] {
    padding: 24px;
    border: none;
    border-bottom: 1px solid #ccc;
    border-radius: 0px;
    margin-bottom: 25px;
    margin-top: 2px;
    width: 70.5%;
    box-sizing: border-box;
    font-family: montserrat;
    color: #2c3e50;
    font-size: 16px;
    letter-spacing: 1px;
}

#msform .input-button {
    padding: 24px;
    border: none;
    border: 0.5px solid #333333 !important;
    border-radius: 7px;
    margin-bottom: 25px;
    margin-top: 2px;
    width: 70.5%;
    box-sizing: border-box;
    font-family: montserrat;
    color: #2c3e50 !important;
    font-size: 16px;
    letter-spacing: 1px;
    background: #fff !important;
}

#msform input::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #ccc;
    text-align: center;
}

#msform input[type="date"]:not(:valid)::-webkit-datetime-edit {
    color: #ccc;
    text-align: center;
}

#msform input:focus,
#msform textarea:focus {
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    border: none;
    font-weight: bold;
    border-bottom: 2px solid skyblue;
    outline-width: 0;
}

#msform .action-button,
#msform .action-button-previous {
    width: 70.5% !important;
    background: #999999 0% 0% no-repeat padding-box;
    border-radius: 7px !important;
    font-weight: bold;
    color: white;
    border: 0 none;
    cursor: pointer;
    /* padding: 10px 5px !important; */
    margin: 10px 5px;
}

#msform .action-button.button-half,
#msform .action-button-previous.button-half {
    width: 34% !important;
}

#msform .action-button:hover,
#msform .action-button:focus {
    box-shadow: 0 0 0 2px white, 0 0 0 3px skyblue;
}

#msform .action-button-previous {
    width: 100px;
    background: #616161;
    font-weight: bold;
    color: white;
    border: 0 none;
    border-radius: 0px;
    cursor: pointer;
    padding: 10px 5px;
    margin: 10px 5px;
}

#msform .action-button-previous:hover,
#msform .action-button-previous:focus {
    box-shadow: 0 0 0 2px white, 0 0 0 3px #616161;
}

select.list-dt {
    border: none;
    outline: 0;
    border-bottom: 1px solid #ccc;
    padding: 2px 5px 3px 5px;
    margin: 2px;
}

select.list-dt:focus {
    border-bottom: 2px solid skyblue;
}

.card {
    z-index: 0;
    border: none;
    border-radius: 0.5rem;
    position: relative;
}

.fs-title {
    font: normal normal 300 21px/32px Helvetica Neue;
    letter-spacing: 0.53px;
    color: #333333;
    text-align: center;
}

#progressbar {
    margin-bottom: 30px;
    overflow: hidden;
    color: lightgrey;
}

#progressbar .active {
    color: #000000;
}

#progressbar li {
    list-style-type: none;
    font-size: 12px;
    width: 25%;
    float: left;
    position: relative;
}

#progressbar #account:before {
    font-family: FontAwesome;
    content: "\f023";
}

#progressbar #personal:before {
    font-family: FontAwesome;
    content: "\f007";
}

#progressbar #payment:before {
    font-family: FontAwesome;
    content: "\f09d";
}

#progressbar #confirm:before {
    font-family: FontAwesome;
    content: "\f00c";
}

#progressbar li:before {
    width: 50px;
    height: 50px;
    line-height: 45px;
    display: block;
    font-size: 18px;
    color: #ffffff;
    background: lightgray;
    border-radius: 50%;
    margin: 0 auto 10px auto;
    padding: 2px;
}

#progressbar li:after {
    content: "";
    width: 100%;
    height: 2px;
    background: lightgray;
    position: absolute;
    left: 0;
    top: 25px;
    z-index: -1;
}

#progressbar li.active:before,
#progressbar li.active:after {
    background: skyblue;
}

.radio-group {
    position: relative;
    margin-bottom: 25px;
}

.radio {
    display: inline-block;
    width: 204;
    height: 104;
    border-radius: 0;
    background: lightblue;
    box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    cursor: pointer;
    margin: 8px 2px;
}

.radio:hover {
    box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.3);
}

.radio.selected {
    box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.1);
}

.fit-image {
    width: 100%;
    object-fit: cover;
}

.g-recaptcha div:first-child {
    margin: auto;
}

.jQueryMosaic {
    float: none !important;
}

.jQueryMosaic > .item > .overlay > .texts h1 {
    color: #fff;
}

.jQueryMosaic > .item > .overlay > .texts h2 {
    color: #fff;
}

/*-------------------------------------------
Side Bar
---------------------------------------------*/
.sidebar nav .social-icon {
    width: 60px;
    height: 60px;
}

.sidebar nav .social-toggle {
    display: block;
}

.sidebar nav {
    position: fixed;
    bottom: 15.5%;
    right: 10px;
    z-index: 2;
}

.sidebar nav ul {
    margin-top: 0;
    margin-bottom: 0rem;
    list-style: none;
}

.sidebar nav ul li {
    margin-bottom: 10px;
}
